import React from 'react';
import cn from 'classnames';

export const SmallSpinner = ({ className }) => {
  return (
    <div className={cn(className)} style={{ margin: '5px' }}>
      <i className="fa fa-spin fa-circle-o-notch " />
    </div>
  );
};

export default SmallSpinner;
