import { LOCALES } from '../index';

import { flattenMessages } from './flattenMessages';

export const consolidateMessages = ({ globalMessages, moduleMessages }) => {
  // stub out consolidatedMessages with keys matching all supported locales
  const consolidatedMessages = LOCALES.reduce((acc, locale) => {
    acc[locale.id] = {};
    return acc;
  }, {});

  // Merge globalMessages and each featureMessage into consolidatedMessages
  Object.keys(consolidatedMessages).forEach(key => {
    consolidatedMessages[key] = globalMessages[key];

    Object.keys(moduleMessages).forEach(moduleKey => {
      consolidatedMessages[key][moduleKey] = moduleMessages[moduleKey][key];
    });
  });

  // Flatten messages
  Object.keys(consolidatedMessages).forEach(key => {
    consolidatedMessages[key] = flattenMessages(consolidatedMessages[key]);
  });

  return consolidatedMessages;
};
