import { settings } from './config-env.json';

const fromConfigEnv = env => {
  const { awsConfig, serviceEndpoints } = settings[env || 'localhost'];
  const { googleMapApiKey, climaCellApiKey } = settings[env || 'prod'];

  return { awsConfig, googleMapApiKey, climaCellApiKey, serviceEndpoints };
};

export default env => {
  return fromConfigEnv(env);
};
