export default {
  table: {
    name: 'Name',
    shortName: 'Short Name',
    userName: 'User Name',
    type: 'Type',
    address: 'Address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    dateRange: 'Date Range',
    token: 'Token',
    state: 'State',
    auto: 'Auto',
    reference: 'Reference',
    userRegions: 'User Regions',
    userPlants: 'User Plants',
    timeZone: 'Time Zone',
    topUpTime: 'Top Up Time',
    replenishmentOptions: 'Replenishment Options',
    inventoryMultiple: 'Inventory Multiple',
    demandMultiple: 'Demand Multiple',
    unitName: 'Unit Name',
    quantity: 'Quantity',
    safetyQuantity: 'Safety Quantity',
    reorderQuantity: 'Reorder Quantity',
    maximumQuantity: 'Maximum Quantity',
    users: 'Users',
    plant: 'Plant',
    region: 'Region',
    channels: 'Channels',
    target: 'Target',
    connectionInformation: 'Connection Information',
    startDateTime: 'Start Date And Time',
    endDateTime: 'End Date And Time',
    vehicles: 'Vehicles',
    carrier: 'Carrier',
    description: 'Description',
    loadSize: 'Load Size',
    components: 'Number Of Components',
    job: 'Job',
    category: 'Category',
  },
};
