let encounteredIncorrectVersion = false;

let callback = () => {};

export const onIncorrectVersion = fn => {
  callback = fn;
};

export const incorrectVersion = () => {
  if (!encounteredIncorrectVersion) {
    encounteredIncorrectVersion = true;
    callback();
  }
};
