export default {
  table: {
    name: 'Name',
    description: 'Description',
    userName: 'User Name',
    state: 'State',
    identifier: 'Identifier',
    devices: 'Devices',
    drivers: 'Drivers',
  },
};
