export default {
  table: {
    name: 'Nombre',
    shortName: 'Nombre Corto',
    userName: 'Nombre de Usuario',
    type: 'Tipo',
    address: 'Dirección',
    latitude: 'Latitud',
    longitude: 'Longitud',
    dateRange: 'Rango de Fechas',
    token: 'Token',
    state: 'Estado',
    auto: 'Automático',
    reference: 'Referencia',
    userRegions: 'Regiones de Usuario',
    userPlants: 'Plantas de Usuario',
    timeZone: 'Zona Horaria',
    topUpTime: 'Tiempo de Recarga',
    replenishmentOptions: 'Opciones de Reposición',
    inventoryMultiple: 'Inventario Múltiple',
    demandMultiple: 'Demanda Múltiple',
    unitName: 'Unidad de Nombre',
    safetyQuantity: 'Cantidad de Seguridad',
    reorderQuantity: 'Cantidad a Reordenar',
    maximumQuantity: 'Cantidad Máxima',
    users: 'Usuarios',
    plant: 'Planta',
    region: 'Región',
    channels: 'Canales',
    target: 'Objetivo',
    connectionInformation: 'Información de Conexión',
  },
};
