export default {
  PRINTED: 'Ticketed',
  TYPED_TIME: 'Ticketed',
  LOAD_TIME: 'Begin Loading',
  LOADING_STARTED: 'Begin Loading',
  LOADING_COMPLETE: 'Finish Loading',
  LOADED_TIME: 'Finish Loading',
  TO_JOB: 'Leave Plant',
  TO_JOB_TIME: 'Leave Plant',
  ARRIVE_JOB: 'Arrive Job',
  ON_JOB_TIME: 'Arrive Job',
  UNLOADING: 'Begin Unload',
  BEGIN_UNLD_TIME: 'Begin Unload',
  END_UNLOADING: 'End Unload',
  WASH_TIME: 'Washing',
  WASHING: 'Washing',
  LEAVE_JOB: 'Leave Job',
  TO_PLANT_TIME: 'Leave Job',
  IN_YARD: 'Arrive Plant',
  AT_PLANT_TIME: 'Arrive Plant',

  PENDING: 'PENDING',
  ACCEPTED: 'RECEIVED',
  'ORDER REQUEST (PENDING)': 'ORDER REQUEST (PENDING)',
  'ORDER REQUEST (REJECTED)': 'ORDER REQUEST (REJECTED)',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  VOIDED: 'VOIDED',
  CANCELLED: 'CANCELLED',
  HOLD: 'HOLD',
  'ON HOLD': 'ON HOLD',
  'CREDIT HOLD': 'CREDIT HOLD',
};
