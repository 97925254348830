export default {
  PASSED: 'Passed',
  FAILED: 'Failed',
  NOT_ASSESSED: 'Not Assessed',
  NO_TEST_RESULT: 'Test Entered',

  taxRate: {
    address: 'Delivery Address',
    customer: 'Customer Address',
    project: 'Project Address',
    location: 'Plant Address',
  },
};
