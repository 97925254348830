import { API as AwsApi } from 'aws-amplify';
import { get } from 'lodash';
import handleResponse from './handleResponse';
import isValidRequest from './isValidRequest';
import { incorrectVersion } from '../../../initialization/FailureHandler';
import rewriteOptions from './rewriteOptions';
import { checkVersion } from '../../../initialization/version';

const method = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DEL: 'del',
  HEAD: 'head',
  ENDPOINT: 'endpoint',
};

const CHECK_API_PATH = false;

class Api {
  onError = () => {
    console.warn('API Error handler not defined.');
  };

  handler = (property, args = []) => {
    const rewrittenArgs = rewriteOptions(args);

    if (CHECK_API_PATH && !isValidRequest(property, rewrittenArgs)) {
      throw new Error(`Unauthorized Access Attempt`);
    }

    return AwsApi[property](...rewrittenArgs)
      .then(response => {
        if(rewrittenArgs[0] === 'API') {
          checkVersion(get(response, 'headers.x-connex-version'));
        }

        return handleResponse(response.data);
      })
      .catch(e => {
        if (get(e, 'response.data.code', '') === 'INCORRECT_VERSION') {
          incorrectVersion();
          throw e;
        } else {
          this.onError(e, property, rewrittenArgs[1], rewrittenArgs[3]);
        }
      });
  };

  get = (...args) => this.handler(method.GET, args);

  post = (...args) => this.handler(method.POST, args);

  put = (...args) => this.handler(method.PUT, args);

  patch = (...args) => this.handler(method.PATCH, args);

  del = (...args) => this.handler(method.DEL, args);

  head = (...args) => this.handler(method.HEAD, args);

  endpoint = (...args) => this.handler(method.ENDPOINT, args);
}

export const API = new Api();
