const dots = [
  '                                                                     ',
  '                                #   #                                ',
  '                              ###   ###                              ',
  '                            #####   #####                            ',
  '                          #####       #####                          ',
  '                        #####           #####                        ',
  '                      #####               #####                      ',
  '                       ##########   ##########                       ',
  '                        #########   #########                        ',
  '                                                                     ',
];

const connex = [
  '                                                                                                                                                 ',
  '        .(########/          ,#########*         ((/.     ,#######.      ((/.     /#######             (########.       ((((((((       ########  ',
  '     #################   .#################/     ######,#############    #####*/############(      (###############*     .(((((((.   ########    ',
  '   ###########/(####    #####################*   #####################   ####################(   (#######.   .#######      *(((/   (#######      ',
  '  (#######             #######,        #######/  #######*      #######   #######,     ,#######  (######        (#####(       ,   /#######        ',
  '  #######             /######(          #######  #######       #######   #######       #######  ######################         ,#######.         ',
  '  #######             *#######         ,#######  #######       #######   #######       #######  ######################       .###########        ',
  '  *#######(            ########       (#######   #######       #######   #######       #######  ,#######                    ###############      ',
  '    ################*   (####################    #######       #######   #######       #######    #################(      #######/  *#######(    ',
  '      ###############(    .###############/      #######       #######   #######       #######      (###############(   ########      (#######*  ',
  '           .,,,,.               .,,,.                                                                     ,,,,.                                  ',
  '                                                                                                                                                 ',
];

const randomLogoChoice = Math.floor(Math.random() * Math.floor(4)) + 1;

switch (randomLogoChoice) {
  case 1:
    console.log(`%c${dots.join('\n')}`, 'color:#E04E39');
    break;
  case 2:
    console.log(`%c${dots.join('\n')}`, 'color:white;background-color:#E04E39');
    break;
  case 3:
    console.log(`%c${connex.join('\n')}`, 'color:white;background-color:#E04E39');
    break;
  case 4:
  default:
    console.log(`%c${connex.join('\n')}`, 'color:#E04E39');
    break;
}
