import { v4 as uuid } from 'uuid';
import SDK from '../@cai-connex/sdk';

export const RecordMetricsRq = async metrics => {
  // ensure an array
  const metricsArray = [].concat(metrics);

  const xConnexId = SDK.getCachedItem('x-connex-id');

  const myHeaders = new Headers();
  myHeaders.append('x-api-key', 'NiBlhF9TMXaIFXFt6rNdk2cQsWM9Oty89oJJ2EHP');
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Accept', 'application/json');
  myHeaders.append('x-connex-id', xConnexId);

  const env = SDK.getEnvironment() || 'unknown';
  const sessionId = SDK.getCachedItem('sessionId');
  const routeId = SDK.getCachedItem('routeId');

  const rpc = JSON.stringify({
    jsonrpc: '2.0',
    id: uuid(),
    method: 'RecordMetricsRq',
    params: {
      Metrics: metricsArray.map(({ values, ...m }) => ({
        EventType: 'ui-metric',
        App: 'CONNEX',
        Environment: env,
        Metric: { ...m, sessionId, routeId },
        Values: values,
      })),
    },
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: rpc,
    redirect: 'follow',
  };

  return fetch('https://analytics.commandalkon.io/v1/metrics', requestOptions);
};
