import React, { useState, useCallback, useContext } from 'react';

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(
      `useModuleContext cannot be rendered outside of the ModuleContext context provider`
    );
  }
  return context;
};

export const AppContextProvider = ({ children, name }) => {
  const [data, _setData] = useState({});

  const setData = useCallback(
    obj => {
      _setData(d => ({ ...d, ...obj }));
    },
    [_setData]
  );

  return (
    <AppContext.Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
