import { API } from './Api';
import SDK from '../index';
import {upperFirst} from 'lodash'

const headers = () => {
  const connexId = SDK.getCachedItem('x-connex-id');
  const connexVersion = SDK.getCachedItem('x-connex-version');

  const result = {};

  if (connexId !== null) {
    result['x-connex-id'] = connexId;
  }

  if (connexVersion) {
    result['x-connex-version'] = connexVersion;
  }

  return result;
};

const post = async (path, params, showError = true) => {
  return API.post('API', path, { body: params, headers: headers() }, showError).then(response => {
    return response;
  });
};

const get = async (path, showError = true) => {
  return API.get('API', path, { body: {}, headers: headers() }, showError).then(response => {
    return response;
  });
};

const del = async (path, showError = true) => {
  return API.del('API', path, { body: {}, headers: headers() }, showError).then(response => {
    return response;
  });
};

export const put = async (path, params, showError = true) => {
  return API.put('API', path, { body: params, headers: headers() }, showError).then(response => {
    return response;
  });
};

export const patch = async (path, params, showError = true) => {
  return API.patch('API', path, { body: params, headers: headers() }, showError).then(response => {
    return response;
  });
};

// TODO: externalize api key (config). Each service will likely have a unique api key.
const SERVICE_API_KEY = '9UCa8lvpgHciPKf6Tr26pu8LDCXjVfkMYCQDWk16'
const knownServices = {
  'Orders': '9UCa8lvpgHciPKf6Tr26pu8LDCXjVfkMYCQDWk16',
  'Workspaces': 'r5U6cRmmCDXFmQfic0KesiRO8sKcUE8aUTxHBLSM',
}

const getServiceParameters = (path) => {
  const pathItems = path.split('/');
  let service = pathItems[3];

  service = upperFirst(service);

  if(!knownServices[service || '']) {
     service = 'Orders';
  }

  const result = {
    name: `${service}ServiceAPI`,
    apiKey: knownServices[service],
  }

  return result;
}

const servicePost = async (path, params, showError = true) => {

  const {name, apiKey} = getServiceParameters(path);

  return API.post(
    name,
    path,
    { body: params, headers: { ...headers(), 'x-api-key': apiKey } },
    showError
  ).then(response => {
    return response;
  });
};

const serviceGet = async (path, showError = true) => {

  const {name, apiKey} = getServiceParameters(path);

  return API.get(
    name,
    path,
    { body: {}, headers: { ...headers(), 'x-api-key': apiKey } },
    showError
  ).then(response => {
    return response;
  });
};

const serviceDelete = async (path, showError = true) => {

  const {name, apiKey} = getServiceParameters(path);

  return API.del(
    name,
    path,
    { body: {}, headers: { ...headers(), 'x-api-key': apiKey } },
    showError
  ).then(response => {
    return response;
  });
};

export const servicePatch = async (path, params, showError = true) => {

  const {name, apiKey} = getServiceParameters(path);

  return API.patch(
    name,
    path,
    { body: params, headers: { ...headers(), 'x-api-key': apiKey } },
    showError
  ).then(response => {
    return response;
  });
};

const hook = async (appId, hookId, params) => {
  const entity = SDK.getEntity();

  if (entity) {
    const path = `/v2/apps/${appId}/${entity.crn}/actions/${hookId}`;

    return API.post('API', path, { body: { input: params }, headers: headers() }).then(response => {
      return response;
    });
  }

  throw new Error('Illegal State - Entity Not Configured');
};

const appAction = async (appId, entityRef, hookId, params) => {
  const path = `/v2/apps/${appId}/${entityRef}/actions/${hookId}`;

  return API.post('API', path, { body: params, headers: headers() })
    .then(response => {
      return response;
    })
    .catch(err => {
      console.error(err);

      throw new Error(err.message);
    });
};

const appFormAction = async (appId, entityRef, relPath, item) => {
  const path = `/v2/apps/${appId}/${entityRef}/actions/${relPath}`;

  return API.post('API', path, { body: item, headers: headers() })
    .then(response => {
      return response;
    })
    .catch(err => {
      console.error(err);

      throw new Error(err.message);
    });
};

const apiDocs = () => {
  return get('/v2/api-docs');
};

export default {
  post,
  get,
  put,
  del,
  patch,
  hook,
  apiDocs,
  appAction,
  appFormAction,
  serviceGet,
  servicePost,
  serviceDelete,
  servicePatch,
  headers,
};
