export default {
  'Manage Profile': 'Administrar Perfil',
  'Switch Themes': 'Cambiar Temas',
  'Switch Companies': 'Cambiar Compañías',
  Support: 'Soporte',
  'Platform Admin': 'Administrador de Plataforma',
  Logout: 'Cerrar Sesión',

  Workspace: 'Espacios de Trabajo',
  Dispatch: 'Despacho',
  Orders: 'Pedidos',
  Tracking: 'Seguimiento',
  Planning: 'Planeación',
  Billing: 'Facturación',
  Setup: 'Configuración',

  'Mobile Ticket': 'Mobile Ticket',
  Shipments: 'Envíos',
  'Change Truck Number': 'Cambiar Número de Camión',
  General: 'General',
  Comments: 'Comentarios',
  'Risk Assessment': 'Evaluación de Riesgos',
  'Driver Sellables': 'Ventas de Conductores',
  'Water Reasons': 'Motivos  para el Agua',

  Buyer: 'Comprador',
  'Accept Materials': 'Aceptar Materiales',

  Seller: 'Vendedor',
  'View Shipments': 'Ver Envíos',

  Reporting: 'Reportes',
  Reports: 'Reportes',

  Analysis: 'Análisis',
  Insights: 'Perspectivas',

  Administration: 'Administración',
  DExA: 'DExA',
  Dashboard: 'Tablero',
  DExAs: 'DExAs',
  'Master Configs': 'Configuraciones Maestras',
  'Query Sets': 'Conjunto de Consultas',
  Queries: 'Consultas',
  Install: 'Instalación',
  Company: 'Compañía',
  Users: 'Usuarios',
  Roles: 'Roles',
  Terms: 'Términos',
  'Master Data': 'Datos Maestros',
  Locations: 'Ubicaciones',
  Customers: 'Clientes',
  Projects: 'Proyectos',
  Products: 'Productos',
  Containers: 'Contenedores',
  Disciplines: 'Disciplinas',
  'Job Areas': 'Áreas de Obras',
  Reasons: 'Motivos',
  'Cost Codes': 'Códigos de Costos',
  'Ticket Tags': 'Etiquetas de Tickets',
  'Resource Codes': 'Códigos de Recursos',
  'Data Values': 'Válores de Datos',
  Processes: 'Procesos',
  'Bulk Ticket': 'Tickets Masivos',
  'Scheduled Tasks': 'Tareas Programadas',

  'Command Assurance': 'Garantía de Command',
  'Assurance Support': 'Soporte de Garantía',
  'Command Message': 'Mensaje a Command',
  Devices: 'Dispositivos',
  Groups: 'Grupos',

  Develop: 'Desarrollo',
  Developer: 'Desarrollador',
  Functions: 'Funciones',
  Hooks: '_Hooks',
  'Data Flows': 'Flujos de Datos',
  'Data Sets': 'Conjunto de Datos',
  Workspaces: 'Espacios de Trabajo',
  Widgets: 'Widgets',
};
