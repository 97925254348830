export default {
  table: {
    id: 'ID',
    name: 'Nombre',
    type: 'Tipos',
    locations: 'Ubicación',
    contacts: 'Nombre de Contacto',
    active: 'Activo',
  },
};
