import React, { createContext, useContext, useEffect, useState } from 'react';
import { SmallSpinner } from '../views/SmallSpinner';
import { useVersion } from './useVersion';
import MandatoryRefresh from './MandatoryRefresh';
import { onChunkFailure } from './SafeImport';
import VersionModal from './VersionModal';

export const InitializationContext = createContext();

const handleRefresh = () => {
  window.location.reload();
};

export const useInitializationContext = () => {
  const context = useContext(InitializationContext);

  if (!context) {
    throw new Error('useInitializationContext cannot be called outside of the InitializationContext context provider');
  }

  return context;
};

const Initialization = ({ children }) => {
  const [chunkFailure, setChunkFailure] = useState(false);
  const version = useVersion();

  useEffect(() => {
    onChunkFailure(() => {
      setChunkFailure(true);
    });
  }, [version.clientVersion, version.handleNewVersion]);

  if (chunkFailure) {
    return <MandatoryRefresh />;
  }

  // Don't render the App until the attempt to obtain the version is complete.
  return (
    <InitializationContext.Provider value={{ version }}>
      <VersionModal version={version} onRefresh={handleRefresh} />
      {version.versionBusy ? <SmallSpinner /> : children}
    </InitializationContext.Provider>
  );
};

export default Initialization;
