import {useCallback, useState} from 'react';
import SDK from './@cai-connex/sdk';
import User from './@cai-connex/sdk/user';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [entity, setEntity] = useState(null);

  const logoutCleanup = useCallback(() => {
    SDK.clearCachedItem('user');
    SDK.clearCachedItem('x-connex-id');
    SDK.setExperience();
    SDK.setEntity(null);

    setUser(null);
    setEntity(null);
    // For iOS app, post message to the RN web view on logout:
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("logout");
    }
  }, [setUser, setEntity]);

  const onLogout = useCallback(e => {
    e && e.preventDefault();

    User.logout()
      .then(() => {
        logoutCleanup();
      })
      .catch(err => {
        console.log(err);
        logoutCleanup();
      });
  }, [logoutCleanup]);

  const logout = useCallback(() => {
    return User.logout().then(() => {
      console.info('Logged out.');
      logoutCleanup();
    })
    .catch(err => {
      console.log(err);
      logoutCleanup();
    });
  }, [logoutCleanup]);

  return {user, setUser, onLogout, entity, setEntity, logout};
};

export default useAuth;
