import { Cache } from 'aws-amplify';
import API from '../api';

const ticketValues = async (appId, pk, supplierId, limit) => {
  return API.get(`/v2/apps/${appId}/${pk}/${supplierId}/ticketvalues${limit ? '?limit=' + limit : ''}`);
};

const listItems = async (appId, pk, typeId, fields) => {
  return API.get(`/v2/apps/${appId}/${pk}/data/${typeId}${(fields && `?$select=${fields}`) || ''}`);
};

const createItem = async (appId, pk, typeId, item) => {
  return API.post(`/v2/apps/${appId}/${pk}/data/${typeId}`, item);
};

const replaceItem = async (appId, pk, typeId, item) => {
  return API.put(`/v2/apps/${appId}/${pk}/data/${typeId}/${item.crn}`, item);
};

const updateItem = async (appId, pk, typeId, item) => {
  return API.patch(`/v2/apps/${appId}/${pk}/data/${typeId}/${item.crn}`, item);
};

const saveItem = async (appId, pk, item) => {
  console.trace();
  const typeId = item.typeId || null;

  if (typeId === null) throw new Error('typeId is required on all data items');

  if (!item.crn) return createItem(appId, pk, typeId, item);

  return replaceItem(appId, pk, typeId, item);
};

const readItem = async (appId, pk, typeId, crn, showError = true) => {
  return API.get(`/v2/apps/${appId}/${pk}/data/${typeId}/${crn}`, showError);
};

const newItem = async (appId, pk, typeId) => {
  return API.get(`/v2/apps/${appId}/${pk}/data/${typeId}/new?required=false`);
};

const deleteItem = async (appId, pk, typeId, crn) => {
  return API.del(`/v2/apps/${appId}/${pk}/data/${typeId}/${crn}`);
};

const lookupItem = async (appId, pk, typeId, crn) => {
  const lookups = Cache.getItem('__lookups__', {});

  lookups[typeId] = lookups[typeId] || (await listItems(appId, pk, typeId));

  Cache.setItem('__lookups__', lookups);

  console.log(lookups);

  return API.get(`/v2/apps/${appId}/${pk}/data/${typeId}/${crn}`);
};

const searchItems = async (appId, pk, typeId, body) => {
  return API.post(`/v2/apps/${appId}/${pk}/data/${typeId}/search`, body);
};

const searchItemsMin = async (appId, pk, typeId, body) => {
  return API.post(`/v2/apps/${appId}/${pk}/data/${typeId}/search/min`, body);
};

const lookupItems = async (appId, pk, typeId, lookupKey) => {
  return API.post(`/v2/apps/${appId}/${pk}/data/${typeId}/search`, { lookupKey });
};

export default {
  lookupItem,
  saveItem,
  listItems,
  createItem,
  newItem,
  replaceItem,
  updateItem,
  readItem,
  deleteItem,
  searchItems,
  searchItemsMin,
  lookupItems,
  ticketValues,
};
