export default {
  table: {
    tsName: 'Proveedor',
    tcName: 'Cliente',
    tpName: 'Proyecto',
    aoName: 'Objeto',
    tdName: 'Destino',
    dDisplayName: 'Dexa',
    dConnectionName: 'Conexión',
    cDateTime: 'Creado el',
    mDateTime: 'Modificado el',
  },
};
