export default {
  loading: 'Cargando...',

  save: 'Guardar',
  cancel: 'Cancelar',
  delete: 'Borrar',
  reset: 'Reestablecer',
  edit: 'Editar',
  rejected: 'Rechazado',
  received: 'Recibido',
  enRoute: 'En ruta',

  PRINTED: 'Ticket Creado',
  TYPED_TIME: 'Fecha de Creación del Ticket',
  LOAD_TIME: 'Fecha de Inicio de Cargue',
  LOADING_STARTED: 'Iniciando Cargue',
  LOADING_COMPLETE: 'Cargue Terminado',
  LOADED_TIME: 'Fecha de Cargue Terminado',
  TO_JOB: 'En Viaje',
  TO_JOB_TIME: 'Fecha de Obra',
  ARRIVE_JOB: 'Llegar a la Obra',
  ON_JOB_TIME: 'Fecha en que Llega a la Obra',
  UNLOADING: 'Iniciando Descarge',
  BEGIN_UNLD_TIME: 'Fecha de Inicio de Descargue',
  END_UNLOADING: 'Descargue Terminado',
  WASH_TIME: 'Fecha de Lavado',
  WASHING: 'Lavando',
  LEAVE_JOB: 'Dejar Obra',
  TO_PLANT_TIME: 'Dejar Obra',
  IN_YARD: 'En Planta',
  AT_PLANT_TIME: 'Tiempo de Llega a la Planta',

  PENDING: 'Pendiente',
  ACCEPTED: 'Recibida',
  'ORDER REQUEST (PENDING)': 'SOLICITUD DE PEDIDO (PENDIENTE)',
  'ORDER REQUEST (REJECTED)': 'SOLICITUD DE PEDIDO (RECHAZADA)',
  CONFIRMED: 'CONFIRMADA',
  REJECTED: 'RECHAZADA',
  VOIDED: 'ANULADA',
  CANCELLED: 'CANCELADA',
  HOLD: 'ESPERA',
  'ON HOLD': 'EN ESPERA',
  'CREDIT HOLD': 'RETENIDO POR CRÉDITO',

  Pending: 'Pendiente',
  Received: 'Recibida',
  Rejected: 'Rechazada',

  loadNumber: 'Carga #{loadNumber}',

  anErrorHasOccurred: 'Ha ocurrido un error',

  loginPage: {
    welcomeBack: 'Bienvenido de nuevo',
    userName: 'Nombre de usuario',
    password: 'Contraseña',
    rememberMe: 'Recordar inicio de sesión',
    login: 'Iniciar la sesión',
    forgotPassword: '¿Has olvidado tu contraseña?',
    privacyPolicy: 'Política de privacidad',
    incorrectLoginData: 'Nombre de usuario o contraseña incorrecta',
    passwordRecovery: 'Recuperación de contraseña',
    passwordRecoveryLabel: 'Por favor ingrese su correo electrónico a continuación',
    passwordRecoveryText:
      'Enviaremos instrucciones para recuperar la contraseña a la dirección de correo electrónico registrada en su cuenta.',
    email: 'Correo',
    submit: 'Enviar',
    backToLoginPage: 'Volver a la página de inicio de sesión',
  },
};
