export default {
  button: {
    create: 'Crear Pedido',
  },
  grid: {
    deliveryWindow: 'Ventana de Entrega',
    deliveryLocation: 'Ubicación de Entrega',
    customer: 'Cliente',
    purchaseOrder: 'Órden de Compra',
    products: 'Productos',
    deliveries: 'Entregas',
    qty: 'Cantidad Entregada',
    customerStatus: 'Estado del Cliente',
    supplierStatus: 'Estado del Proveedor',
    orderReference: 'Referencia',
  },
  products: {
    productsServices: 'Productos y Servicios',
    closestProducts: 'Mostrar los productos más cercanos a mi ubicación de entrega',
  },
};
