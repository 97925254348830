function RpcException (error) {
  const {code, message,data} = error;
  this.type = 'RpcException';
  this.code = code;
  this.message = message;
  this.data = data;
  this.toString = function () {
    return `${error.code}: ${error.message} - ${error.data.details.map(d => `\n\t- ${d}`)}\n`
  }
}

export default response => {
  if (response && (response.jsonrpc || response.connexrpc)) {
    if (response.error) {
      /*TODO: see if shape of error will always be :
          {error: {
            code: string,
            data: {
              details: array of strings
            }
            message: string
          }}
       */
      throw new RpcException(response.error);
    } else {
      //TODO: return just the response data.
      return response;
    }
  }
  return response;
};
