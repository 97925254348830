export default {
  table: {
    purchaseOrder: 'Órden de Compra',
    description: 'Descripción',
    location: 'Ubicación',
    products: 'Productos',
    status: 'Estado',
    projectDate: 'Fecha del Proyecto',
    projectReference: 'Referencia del Proyecto',
  },
};
