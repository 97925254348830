export default {
  table: {
    tsName: 'Supplier',
    tcName: 'Customer',
    tpName: 'Project',
    aoName: 'Object',
    tdName: 'Destination',
    dDisplayName: 'Dexa',
    dConnectionName: 'Connection',
    cDateTime: 'Created At',
    mDateTime: 'Modified At',
  },
};
