import useAuth from "core/useAuth";
import { useHistory, useLocation } from "react-router";


export const useElectron = () => {
  const electron = window.require && window.require('electron');
  const history = useHistory();
  const location = useLocation();
  const { logout } = useAuth();

  const handleLogin = (user) => {
    if (!electron) {
      return false;
    }
    electron.ipcRenderer.send('connex-login', { connexUser: user, redirect: location.pathname.includes(':entityRef') });
    electron.ipcRenderer.on('connex-logout', () => {
      logout().then(() => {
        history.push('/login');
      });
    });
    return true;
  }

  return {
    electron,
    handleLogin,
  };
};
