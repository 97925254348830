export default {
  table: {
    purchaseOrder: 'Purchase Order',
    description: 'Description',
    location: 'Location',
    products: 'Products',
    status: 'Status',
    projectDate: 'Project Date',
    projectReference: 'Project Reference',
  },
};
