export default {
  historyTable: {
    carrier: 'Transportadora',
    truckId: 'Código de Camión',
    truckName: 'Nombre del Camión',
    created: 'Fecha de Creación',
    command: 'Comando',
    sent: 'Enviado',
    status: 'Estado',
    responded: 'Respondido',
  },
};
