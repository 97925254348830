import React from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const VersionModal = ({ className, version, onRefresh }) => {
  const isLocal = window?.location?.hostname?.includes('localhost');

  return (
    <Modal isOpen={!isLocal && version.versionModalVisible}>
      <ModalHeader>New Version Available</ModalHeader>
      <ModalBody className={className}>
        <div>A new version of CONNEX is available. Please refresh.</div>
        <div className="version-info">
          <div className="current-version">Your Version: {version.clientVersion}</div>
          <div className="new-version">Latest Version: {version.latestVersion}</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={version.hideVersionModal}>
          Later
        </Button>
        <Button color="secondary" onClick={onRefresh}>
          Refresh
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default styled(VersionModal)`
  .version-info {
    margin-top: 10px;
    margin-left: 20px;
  }
`;
