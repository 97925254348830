export default {
  table: {
    id: 'ID',
    name: 'Name',
    type: 'Types',
    locations: 'Location',
    contacts: 'Contact Name',
    active: 'Active',
  },
};
