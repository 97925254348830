import { find, get } from 'lodash';
import { LOCALES } from '../index';
import SDK from '../../@cai-connex/sdk';
import AdminData from '../../@cai-connex/sdk/admin-data';

export const isLocaleSupported = locale => {
  return !!find(LOCALES, l => l.id === locale);
};

export const getBrowserLocale = () => {
  const browserLocale = (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';

  console.log('browser locale', browserLocale);

  return isLocaleSupported(browserLocale) ? browserLocale : 'en-US';
};

export const fetchPreferredLocale = async () => {
  let preferredLocale = '';

  const user = SDK.getUser();

  if (user) {
    const profile = await AdminData.getUserProfile(user.uid);
    preferredLocale = get(profile, 'settings.preferredLocale', '');
  }

  return isLocaleSupported(preferredLocale) ? preferredLocale : 'en-US';
};
