import {Cache} from 'aws-amplify';

const setAwsConfig = (value) => {
  Cache.setItem('awsConfig', value);
};

const getAwsConfig = () => {
  return Cache.getItem('awsConfig');
};

const getDefaultRegion = () => {
  return getAwsConfig()['x-aws-region']
};

const getDataTableName = () => {
  return getAwsConfig()['x-aws-dynamo-table']
};

const getCognitoRegion = () => {
  return getAwsConfig()['aws_cognito_region']
};

const getUserPoolId = () => {
  return getAwsConfig()['aws_user_pools_id']
};

const getBaseUrl = () => {
  const config = getAwsConfig()['aws_cloud_logic_custom'];
  return config[0]['endpoint'];
};

const getApiKey = () => {
  return getAwsConfig()['x-api-key'];
};

const getGoogleMapApiKey = () => {
  return Cache.getItem('googleMapApiKey');
};

const setGoogleMapApiKey = (value) => {
  Cache.setItem('googleMapApiKey', value);
};

const getClimaCellApiKey = () => {
  return Cache.getItem('climaCellApiKey');
};

const setClimaCellApiKey = (value) => {
  Cache.setItem('climaCellApiKey', value);
};

const setServiceEndpoints = (value = {}) => {
  Cache.setItem('serviceEndpoints', value);
}

const getServiceEndpoints = (value = {}) => {
  return Cache.getItem('serviceEndpoints');
}

export default {
  setAwsConfig,
  getCognitoRegion,
  getDataTableName,
  getUserPoolId,
  getDefaultRegion,
  getBaseUrl,
  getApiKey,
  getGoogleMapApiKey,
  setGoogleMapApiKey,
  getClimaCellApiKey,
  setClimaCellApiKey,
  setServiceEndpoints,
  getServiceEndpoints,
}
