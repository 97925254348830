import { useCallback, useEffect, useRef } from 'react';
import { isFunction } from 'lodash';
import { UI } from 'connex-sdk-react';
import API from '../../sdk/api';

// https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makeCancelable = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => {
      return isCanceled ? null : resolve(val);
    });
    promise.catch(error => {
      return isCanceled ? null : reject(error);
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
};

/*
  Creates a cancellable promise from the given fn
  and cancels it when the component unloads.

  Since the v3 api sdk is external it doesn't have a handle
  on the app's API instance (and its centralized error handling).
  So, it's handled a bit differently
 */

const useApiCall = fn => {
  const apiCall = useRef(null);

  if (isFunction(fn)) {
    // typical case
    apiCall.current = fn;
  } else if (fn.rpc) {
    // for v3's rpc
    apiCall.current = (...args) => {
      const { path, request } = fn.rpc(...args);
      return API.post(path, request).then(response => {
        if (response?.Issues?.length) {
          console.log(args, response);
        }
        return response;
      });
    };
  }

  const cancelablePromise = useRef({ cancel: () => {} });

  useEffect(() => {
    return () => {
      cancelablePromise.current.cancel();
    };
  }, [cancelablePromise]);

  const makeApiCall = useCallback(
    (...args) => {
      const cancelable = makeCancelable(apiCall.current(...args));
      cancelablePromise.current = cancelable;
      return cancelable.promise;
    },
    [cancelablePromise]
  );

  return makeApiCall;
};

export default useApiCall;
