export default {
  PASSED: 'Exitosa',
  FAILED: 'Fallida',
  NOT_ASSESSED: 'Sin Evaluar',
  NO_TEST_RESULT: 'Prueba Ingresada',

  tabs: {
    details: 'Detalles',
    prices: 'Precios',
    driver: 'Conductor',
    statuses: 'Estados',
    batchResults: 'Resultados por Lotes',
    testResults: 'Resultados de Pruebas',
    images: 'Imágenes',
    sharing: 'Compartir',
    terms: 'Términos y Condiciones',
    cert: 'Declaración de Certificación',
    support: 'Soporte',
  },

  ticket: 'Ticket {hasTicketId, select, true { - } other {}}',

  convertToPdf: 'Convertir a PDF',
  pdfFields: 'Campos del PDF',
  anErrorHasOccurred: 'Ha ocurrido un error',

  statusChangeTime: 'Fecha de Cambio de Estado',
  statusChangeReason: 'Motivo de Cambio de Estado',
  statusChangeUser: 'Usuario que cambió el Estado',
  latitude: 'Latitud',
  longitude: 'Longitud',
  ticketNumber: 'Número de Ticket',
  dispatchDateTime: 'Fecha/Hora de Despacho',
  purchaseOrder: 'Órden de Compra',
  gross: 'Peso Bruto',
  tare: 'Peso Tara',
  net: 'Peso Neto',
  uom: 'UDM',
  productCostCode: 'Código de Costo del Producto',
  products: 'Productos',
  product: 'Producto.',
  delete: 'Eliminar',
  save: 'Guardar',
  vehicle: 'Vehículo',
  carrier: 'Transportadora',
  vehicleType: 'Tipo de Vehículo',
  driver: 'Conductor',
  licenseTag: 'Etiqueta de Licencia',
  maxVehicleGrossWeight: 'Peso Bruto Máximo por Vehículo',
  jobArea: 'Área de Obra',
  dispatchOrder: 'Órden de Despacho',
  originPlant: 'Planta de Origen',
  dotCertification: 'Certificación DOT',
  contractNumber: 'Número de Contrato',
  isPrevailingWage: 'Salario Prevaleciente',
  deliveryMethod: 'Método de Entrega',
  internalComment: 'Comentario Interno',
  instructions: 'Instructiones',
  address: 'Dirección',
  address2: 'Dirección 2',
  city: 'Ciudad',
  stateProvince: 'Estado/Provincia',
  postalCode: 'Código Postal',
  country: 'País',
  customer: 'Cliente',
  project: 'Proyecto',
  customerJob: 'Obra del Cliente',
  supplier: 'Proveedor',
  discipline: 'Disciplina',
  invoiceCode: 'Código de Factura',
  weighmaster: 'Peso Maestro',
  edit: 'Editar',
  details: 'Detalles',
  productDetails: 'Detalles de Producto',
  addEditProduct: 'Agregar/Editar Producto',
  formula: 'Fórmula',
  mixId: 'Código de Mezcla',
  specificGravity: 'Gravedad Específica',
  isPrimary: '¿Es Primario?',
  quantity: 'Cantidad',
  unitOfMeasure: 'Unidad de Medida',
  productUnitCost: 'Costo Unitario de Producto',
  haulCostCode: 'Código de Costo de Transporte',
  haulUnitCost: 'Costo Unitario de Producto',
  productResourceCode: 'Código de Recurso del Producto',
  customerPoLine: '_Customer PO Line',
  neatlineQuantity: 'Cantidad Precisa',
  neatlineUnitOfMeasure: 'Unidad de Medida Precisa',

  taxLocation: 'Impuesto de la Ubicación',
  taxJurisdiction: 'Impuesto de Jurisdicción',

  taxRate: {
    address: 'Dirección de Entrega',
    customer: 'Dirección del Cliente',
    project: 'Dirección del Proyecto',
    location: 'Dirección de la Planta',
  },

  pricing: {
    product: 'Producto',
    idDescription: 'ID / Descripción',
    unitPrice: 'Precio Unitario',
    quantity: 'Cantidad',
    extPrice: 'Precio Extendido',
    stateTax: 'Impuesto Estatal',
    countyTax: 'Impuesto por País',
    cityTax: 'Impuesto por Ciudad',
    districtTax: 'Impuesto Distrital',
    totalPrice: 'Precio Total',
    reprice: 'Revalorizar Usando Jurisdición Seleccionada',
    totals: 'Totales',
  },
  batch: {
    batchData: 'Datos de Automatización',
    moisture: 'Humedad',
    mixInfo: 'Información de Mezcla',
    maxWaterAdd: 'Máxima Agua por Agregar',
    actualWc: '_Actual W/C',
    maxWc: '_Max W/C',
    material: 'Material',
    batched: 'Automatizado',
    variance: 'Variación',
  },
  moisture: {
    material: 'Material',
    moisture: '% de Humedad',
  },
  test: {
    createdByCompany: 'Creado por la Compañía',
    lastModifiedBy: 'Última Vez Modificado por',
    createdTime: 'Fecha de Creación',
    modifiedTime: 'Fecha de Modificación',
    testStatus: 'Estado de Pruebas',
    addNewTestResult: 'Agregar Nuevo Resultado de Prueba',
    setup: 'Configuración',
    slip: '_Slip',
    fixed: 'Fijo',

    finalDischargeTime: 'Fecha Final de Descargue',
    workType: 'Tipo de Trabajo',
    paving: 'Pavimentación',
    structural: 'Estructural',
    nonStructural: 'No Estructural',
    flowable: 'Fluído',
    misc: 'Miscélanea',

    formType: 'Tipo de Forma',
    locationDescription: 'Descripción de la Ubicación',
    station: 'Estación',
    payItems1: 'Item Pago 1',
    quantity1: 'Cantidad 1',
    payItems2: 'Item Pago 2',
    quantity2: 'Cantidad 2',
    payItems3: 'Item Pago 3',
    quantity3: 'Cantidad 3',
    sample: 'Muestra',
    waterAdded1: 'Agua Agregada 1',
    waterAdded2: 'Agua Agregada 2',
    revolutions1: 'Revoluciones 1',
    revolutions2: 'Revoluciones 2',
    airPctResult1: 'Resultado de % de Aire 1',
    airPctResult2: 'Resultado de % de Aire 2',
    airLoss: 'Pérdida de Aire',
    slumpIn1: 'Asentamiento en 1',
    slumpIn2: 'Asentamiento en 2',
    samplingMethod: 'Método de Muestreo',
    select: 'Seleccionar...',
    beginningOfLoad: 'Inicio de Carga',
    compositeSample: 'Muestra de Compuesto',
    conveyor: 'Cinta Transportadora',
    endOfLoad: 'Fin de Carga',
    fromPumpHose: 'Desde la Manguera de la Bomba',
    middleOfLoad: 'Mitad de Carga',
    notInAccordanceWithAstm: '_Not in Accordance with ASTM',
    pointOfPlacement: 'Punto de Colocación',
    samplingId: 'Código de Muestreo',
    numberOfSpecimens: 'Número de Ejemplares',
    calculate: 'Calcular',
    materialTemp1: 'Material Temporal 1',
    materialTemp2: 'Material Temporal 2',
    ambientTemp1: 'Temperatura Ambiente 1',
    ambientTemp2: 'Temperatura Ambiente 2',
    humidityPct: '% de Humedad Ambiental',
    wind: 'Aire',
    evaporationRate: 'Tasa de Evaporación',
    efflux: 'Efusión',
    waste: 'Desperdicio',
    yield: 'Rendimiento',
    unitWeight: 'Unidad de Peso',
    generalInfo: 'Información General',
    technicianArrivalTime: 'Tiempo de Llegada del Técnico',
    sampleTime: 'Tiempo de Muestra',
    note: 'Nota',
    liftNumber: '_Lift Number',
    liftThickness: '_Lift Thickness',
  },
  images: {
    invalid: 'El Tipo de Archivo Seleccionado para Cargar es Inválido.',
    newImage: 'Nueva Imagen',
    uploadedDate: 'Cargado: {createDate, date, medium} {createDate, time, long}',
    preview: 'Vista Previa',
    discardImage: 'Descartar Imagen',
    keepImage: 'Conservar Imagen',
    uploadingImage: 'Cargando Imagen',
    areYouSure: '¿Está seguro que desea borrar el archivo adjunto?',
  },
  sharing: {
    date: 'Fecha',
    partner: 'Socio',
    matchedOn: '_Matched On**',
    sharedAreas: 'Áreas Compartidas**',
  },

  support: {
    originatorSystemType: 'Tipo de Sistema de Origen',
    originatorSystemID: 'Código de Sistema de Origen',
    originatorClientID: 'Código de Cliente de Origen',
    originatorRecordID: 'Código de Registro de Origen',
    originatorCreatedDateTime: 'Fecha de Creación de Origen',
    originatorModifiedDateTime: 'Fecha de Modificación de Origen',
  },
};
