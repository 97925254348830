/* eslint-disable */
import { SDK as SDK_UI } from 'connex-sdk-react';
// General styles for the v4 Design
import 'connex-sdk-react/styles.css';
import { find, get } from 'lodash';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { HashRouter, Route, Switch, useHistory, useLocation, withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { v4 as uuid } from 'uuid';
import Configure from '../configure';
import SDK from './@cai-connex/sdk';
import { API } from './@cai-connex/sdk/api/Api';
import Users from './@cai-connex/sdk/user';
import { recordMetric } from './analytics';
import { AppContextProvider, useAppContext } from './AppContext';
import { ModalContextProvider, useModalContext } from './apps/components/common/modal/ModalContext';
import { Internationalization, useIntlContext } from './i18n';
import { getBrowserLocale } from './i18n/util/locale';

import Initialization from './initialization';
import { safeImport } from './initialization/SafeImport';
import SSOCallbackRedirect from './SSOCallbackRedirect';
import useAuth from './useAuth';
import { getTheme } from './views/setup/cost-book/styled/theme';
import SmallSpinner from './views/SmallSpinner';
import { useElectron } from './apps/components/dexas/hooks/useElectron';

import('antd/dist/antd.css');

const AppAuthenticated = React.lazy(() => safeImport(import('./App-Authenticated')), <div />);
const AppUnauthenticated = React.lazy(() => safeImport(import('./App-Unauthenticated')));
const Onboarding = React.lazy(() => safeImport(import('./apps/components/public/onboarding')));

// TODO: Configure = 599,200 bytes
Configure.initialize(SDK.getEnvironment() || null);

export const feedback = (result, good, bad) => {
  if (typeof result === 'object' && result.hasOwnProperty('message')) {
    toast.error(result.message, { ...result.options });
  } else if (typeof good === 'string' && typeof bad === 'string') {
    (result && toast.success(good)) || toast.error(bad);
  } else {
    alert(result);
  }
};

const App = props => {
  const [initialPath, setInitialPath] = useState(null);
  const [themeName, setThemeName] = useState(SDK.getCurrentTheme('dark'));
  const history = useHistory();
  const location = useLocation();
  const { user, setUser, onLogout, entity, setEntity, logout } = useAuth();
  const { closeModal } = useModalContext();
  const { data } = useAppContext();
  const { electron, handleLogin: handleLoginForElectron } = useElectron();

  const { setUserLocale } = useIntlContext();

  const onLogin = useCallback((user, path) => {
    let entity = SDK.getEntity();

    setUserLocale(user?.settings?.preferredLocale || getBrowserLocale());

    if (path === '/login') {
      return;
    }

    let userSettings = null;
    if (entity && user.settings && user.settings.location) {
      userSettings = find(user.settings.location, { crn: entity.crn });
    }

    const preferredLocation = userSettings && userSettings.preferredLocation;

    // set preferred path
    if (preferredLocation) {
      path = preferredLocation;
    }

    if (props.initialWindowLocation && handleLoginForElectron(user)) {
      path = props.initialWindowLocation;
    }

    // Some users are really vehicle tracking devices with a login to identify them
    const trackingEntities = Users.userIsTrackingDevice(user);

    if (trackingEntities.length > 0) {
      entity = trackingEntities[0];
      path = `/apps/connex/device-tracker/${entity.crn}/vehicles/${user.uid}`;
    }

    setUser(user);
    setEntity(entity);
    setInitialPath(path);

    if (path) {
      history.push(path);
    }
  }, []);

  const toggleAlert = useCallback((message, type = 'Error') => {
    switch (type) {
      case 'Success':
        toast.success(message);
        break;
      case 'Info':
        toast.info(message);
        break;
      case 'Warn':
        toast.warn(message);
        break;
      default:
        toast.error(message);
        break;
    }
  }, []);
  const restoreSession = useCallback(() => {
    if (document.getElementById('iosLoginInjected').innerHTML === 'true') {
      return;
    }
    const restoredUser = SDK.getUser();

    const restoredEntity = SDK.getEntity();

    const connexId = SDK.getCachedItem('x-connex-id');
    if (restoredUser !== null && connexId !== null) {
      const usePinned = restoredUser.settings && restoredUser.settings.usePinnedLocation === true;
      const tickets = restoredEntity && `/apps/connex/${restoredEntity.crn}/tickets/accept-materials`;

      let userSettings = null;
      if (restoredEntity && restoredUser.settings && restoredUser.settings.location) {
        userSettings = find(restoredUser.settings.location, {
          crn: restoredEntity.crn,
        });
      }

      const preferredLocation = (userSettings && userSettings.preferredLocation) || tickets;
      const windowLocation =
        (!props.initialWindowLocation.includes('/login') && props.initialWindowLocation.replace('#', '')) || null;

      let path = windowLocation || preferredLocation;

      if (windowLocation && handleLoginForElectron(restoredUser)) {
        path = windowLocation;
      }

      path = (usePinned && SDK.getPinnedLocation(path)) || path;

      setUser(restoredUser);
      setEntity(restoredEntity);

      if ((path && path !== location.pathname) || electron) {
        setInitialPath(path);
        history.push(path);
      }
      return;
    }
    setInitialPath('/login');
  }, [setUser, setEntity, setInitialPath]);

  useEffect(() => {
    if (themeName) {
      import(`./scss/style-${themeName}.scss`);
    }
  }, [themeName]);

  const switchTheme = useCallback(() => {
    const themeName = SDK.getCurrentTheme('dark') === 'dark' ? 'light' : 'dark';
    setThemeName(themeName);
    SDK.setCurrentTheme(themeName);
    window.location.reload();
  }, [SDK]);

  useEffect(() => {
    window.alert = toggleAlert;

    const sessionId = uuid();
    SDK.cacheItem('sessionId', sessionId);

    recordMetric({
      type: 'app-initialization',
      data: { sessionId },
    });
  }, []);

  useEffect(() => {
    restoreSession();
  }, [restoreSession]);

  useEffect(() => {
    if (user && location.pathname === '/login') {
      restoreSession();
    }

    API.onError = (e = {}, method, path, showError = true) => {
      if (e.type) {
        // True for RpcExceptions
        feedback(`[${e.code}] - ${e.message}`);
      } else if (!e.response) {
        if (e.message === 'Network Error') {
          showError && feedback('Network Error');
          throw e;
        } else {
          feedback('An error has occurred.');
          throw e;
        }
      } else {
        const { status } = e.response;
        const reason = get(e, 'response.data.code');

        if (status === 403 && reason === 'INVALID_SESSION') {
          console.info('Invalid Session.');
          closeModal();
          logout().then(() => {
            history.push('/login');
          });
          toast.dismiss();
          feedback({
            message: 'Your session has expired.',
            options: { autoClose: false },
          });
          throw e;
        } else if (status === 403 && reason === 'FORBIDDEN_SESSION') {
          console.info('Forbidden Session.');
          closeModal();
          logout().then(() => {
            history.push('/login');
          });
          toast.dismiss();
          feedback({
            message: get(e, 'response.data.message', 'Forbidden'),
            options: { autoClose: false },
          });
          throw e;
        } else if (status === 403 && get(e, 'response.data.message') === 'incorrect username or password') {
          throw e;
        } else if (status === 403) {
          const atLogin = location.pathname === '/login';
          closeModal();
          if (!atLogin) {
            logout().then(() => {
              history.push('/login');
            });
          }
          toast.dismiss();
          feedback(
            atLogin
              ? 'We are unable to process your login.  If the problem persists, please contact support.'
              : 'You have been logged out.'
          );
          throw e;
        } else {
          feedback(get(e, 'response.data', 'Server Error Has Occurred.'));
          throw get(e, 'response.data', e);
        }
      }
    };
  }, [API, history, location]);

  return (
    <div className="app-root">
      <Suspense fallback={<SmallSpinner />}>
        <Switch>
          <Route path="/onboarding" component={Onboarding} />
          {user && !data.fatal && initialPath !== '/login' ? (
            <Suspense fallback={<SmallSpinner />}>
              <AppAuthenticated
                user={user}
                entity={entity}
                initialPath={initialPath}
                switchTheme={switchTheme}
                onLogout={onLogout}
                toggleAlert={toggleAlert}
                electron={electron}
              />
            </Suspense>
          ) : (
            <SSOCallbackRedirect>
              <AppUnauthenticated onLogin={onLogin} />
            </SSOCallbackRedirect>
          )}
        </Switch>
      </Suspense>

      <div style={{ position: 'fixed', width: '100%', top: 50, zIndex: 1051 }}>
        <ToastContainer autoClose={3000} position={'top-center'} closeOnClick={true} />
      </div>
    </div>
  );
};

export default props => {
  const { THEMES_v4, ThemeProvider: SDK_ThemeProvider } = SDK_UI;
  const themeName = SDK.getCurrentTheme('dark');
  const theme_v3 = getTheme(themeName);
  const theme_v4 = THEMES_v4[themeName];

  const AppWithRouter = withRouter(App);

  return (
    <Initialization>
      <Internationalization>
        {/* We need to use several theme providers:
        ThemeProvider - to provide theme object for the project components
        SDK_ThemeProvider - to provide theme object for the SDK components */}
        <ThemeProvider theme={{ ...theme_v3, ...theme_v4 }}>
          <SDK_ThemeProvider theme={{ ...theme_v3, ...theme_v4 }}>
            <ModalContextProvider>
              {/* getUserConfirmation noop is used to override react-router Prompt behaviour */}
              <HashRouter getUserConfirmation={() => {}}>
                <AppContextProvider>
                  <AppWithRouter {...props} />
                </AppContextProvider>
              </HashRouter>
            </ModalContextProvider>
          </SDK_ThemeProvider>
        </ThemeProvider>
      </Internationalization>
    </Initialization>
  );
};
