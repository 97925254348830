// TODO: move this somewhere else.


// TODO color11 has been added for tooltips, instead of static color in Component
const dark = {
  highlight: '#DF4E39',
  color1: '#2C2C2D',
  color2: '#424344',
  color3: '#2E2F2F',
  color4: '#EAEAEA',
  color5: '#252525',
  color6: '#303030',
  color7: '#353636',
  color8: '#777777',
  color9: '#C0C1C1',
  color10: '#58595B',
  color11: '#E04D37',
};

const light = {
  highlight: '#DF4E39',
  color1: '#C0C1C1',
  color2: '#EAEAEA',
  color3: '#424344',
  color4: '#969696',
  color5: '#DCDCDC',
  color9: '#2C2C2D',
  color10: '#F1F2F2',
};

const common = {
  flexContainer: `
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
};

const themes = {
  dark: {
    ...common,
    module: {
      backgroundColor: dark.color6,
      header: {
        backgroundColor: dark.color1,
        color: dark.color4,
        border: `1px solid ${dark.color5}`,
      },
      color: dark.color4,
      borderColor: dark.color5,
      overlay: {
        spinner: {
          color: light.color1,
        },
      },
    },
    table: {
      header: {
        backgroundColor: dark.color2,
        color: dark.color4,
        cell: {
          border: `1px solid ${dark.color3}`,
        },
      },
      row: {
        backgroundColor: dark.color7,
        alt: {
          backgroundColor: dark.color2,
        },
        hover: {
          backgroundColor: '#6b5756',
        },
      },
      cell: {
        borderColor: dark.color3,
        border: `1px solid ${dark.color3}`,
        list: {
          alt: {
            backgroundColor: 'rgba(255,255,255,.1)',
          },
        },
      },
    },
    colors: { ...dark },
    modal: {
      header: {
        backgroundColor: dark.color1,
      },
      body: {
        backgroundColor: dark.color4,
      },
      footer: {},
      table: {
        header: {
          backgroundColor: dark.color2,
          color: dark.color4,
        },
        backgroundColor: dark.color8,
      },
    },
    tooltip: {
      backgroundColor: dark.color11,
      color: dark.color5,
    },
    tabs: {
      tabPanel: {
        backgroundColor: dark.color10,
      },
      tab: {
        color: dark.color9,
        active: {
          backgroundColor: dark.color1,
        },
      },
    },
  },
  light: {
    ...common,
    module: {
      backgroundColor: light.color2,
      header: {
        backgroundColor: light.color1,
        color: light.color3,
        border: `1px solid ${light.color4}`,
      },
      spinner: 'opacity: .5;',
      color: light.color3,
      overlay: {
        spinner: {
          color: light.color1,
        },
      },
    },
    table: {
      header: {
        backgroundColor: light.color1,
        color: light.color3,
        cell: {
          border: `1px solid ${light.color4}`,
        },
      },
      row: {
        backgroundColor: light.color1,
        alt: {
          backgroundColor: light.color2,
        },
        hover: {
          backgroundColor: '#6b5756',
        },
      },
      cell: {
        borderColor: light.color4,
        border: `1px solid ${dark.color3}`,
        list: {
          alt: {
            backgroundColor: 'rgba(0,0,0,.15)',
          },
        },
      },
    },
    colors: { ...light },
    modal: {
      header: {
        backgroundColor: light.color1,
      },
      body: {
        backgroundColor: light.color2,
      },
      footer: {},
      table: {
        header: {
          backgroundColor: light.color1,
          color: light.color3,
        },
        backgroundColor: light.color5,
      },
    },
    tooltip: {
      backgroundColor: light.color4,
      color: light.color3,
    },
    tabs: {
      tabPanel: {
        backgroundColor: light.color10,
      },
      tab: {
        color: light.color9,
        active: {
          backgroundColor: light.color1,
        },
      },
    },
  },
};

export const getTheme = theme => themes[theme];
