import Amplify from 'aws-amplify';
import Config from './core/@cai-connex/sdk/config';
import getConfig from './getConfig';

import('./license').then(module => {
  module.default.register();
});

const initialize = env => {
  const { awsConfig, googleMapApiKey, climaCellApiKey, serviceEndpoints } = getConfig(env);

  Config.setAwsConfig(awsConfig);
  Config.setGoogleMapApiKey(process.env.REACT_APP_GOOGLE_API_KEY || googleMapApiKey);
  Config.setClimaCellApiKey(climaCellApiKey);
  Config.setServiceEndpoints(serviceEndpoints);

  const apiConfig = awsConfig.aws_cloud_logic_custom;

  // Crazy way we have to set a custom header for Amplify API calls
  apiConfig[0].custom_header = async () => {
    return {
      'x-api-key': awsConfig['x-api-key'],
    };
  };

  // Configure all Amplify services
  Amplify.configure(awsConfig);
};

export default {
  initialize,
};
