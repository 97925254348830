export default {
  historyTable: {
    carrier: 'Carrier',
    truckId: 'Truck ID',
    truckName: 'Truck Name',
    created: 'Created',
    command: 'Command',
    sent: 'Sent',
    status: 'Status',
    responded: 'Responded',
  },
};
