let clientVersion = null;
let latestVersion = null;

let cb = () => {
  alert(`A new version of CONNEX is available. Please refresh.`);
};

export const onNewVersion = ({ version, callback }) => {
  clientVersion = version;
  latestVersion = version;

  cb = callback;
};

export const checkVersion = version => {
  if (!version || !clientVersion) return;

  if (version === 'unknown') return;

  if (version < clientVersion || version < latestVersion) return;

  if (version !== latestVersion && version !== clientVersion) {
    cb(version);
    latestVersion = version;
  }
};
