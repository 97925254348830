import './internet-explorer';
import './react-devtools-hook';
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import ReactDOM from 'react-dom';
import App from './core/App';
import './polyfill';

import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.css';
import './logo-to-console';

ReactDOM.render(<App initialWindowLocation={window.location.hash} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
