import { v4 as uuid } from 'uuid';
import moment from 'moment';

export class Queue {
  constructor(fn, intervalMs) {
    this.fn = fn;
    this.queue = [];
    this.intervalMs = intervalMs;
    this.unsuccessfulAttempts = 0;
    this.firstUnsuccessfulAttemptTimestamp = null;

    this.interval = setInterval(() => {
      this.process();
    }, intervalMs);
  }

  add(item) {
    this.queue.push(item);
  }

  stop() {
    clearInterval(this.interval);
  }

  process() {
    if (this.queue.length) {
      const batchId = uuid();

      // additional items could come in while we're processing,
      // so isolate items currently in the queue while we post them.
      const toBePosted = this.queue.splice(0).map(item => ({ ...item, batchId }));

      this.fn(toBePosted)
        .then(response => {
          if (response.error) {
            throw new Error();
          }

          if (this.unsuccessfulAttempts > 0) {
            const now = moment();
            this.add({
              type: 'ui-info',
              data: { type: 'metrics', id: 'error-recovery' },
              additionalData: { failedAttempts: this.unsuccessfulAttempts },
              values: { run_time: now.diff(this.firstUnsuccessfulAttemptTimestamp) },
            });
          }

          this.unsuccessfulAttempts = 0;
          this.firstUnsuccessfulAttemptTimestamp = null;

          return response;
        })
        .catch(() => {
          this.unsuccessfulAttempts += 1;
          if (this.unsuccessfulAttempts === 1) {
            this.firstUnsuccessfulAttemptTimestamp = moment();
          }
          // put them back
          this.queue.splice(0, 0, ...toBePosted);
        });
    }
  }
}
