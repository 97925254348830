const SSOCallbackRedirect = ({ children }) => {
  const isSSO = window.location.pathname.includes('sso-auth');
  if (isSSO) {
    const code = window.location?.search?.split('=')[1];
    const redirectTo = window.location.protocol + '//' + window.location.host + '/#/sso-auth/' + code;
    window.location.href = redirectTo;
  }

  return children;
};

export default SSOCallbackRedirect;
