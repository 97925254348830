import React, { useRef, useCallback, useContext, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const ModalContext = React.createContext();

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext cannot be rendered outside of the ModalContext context provider`);
  }
  return context;
};

export const ModalContextProvider = ({ children }) => {
  const Component = useRef(<div>Modal</div>);

  const [title, setTitle] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const toggleModal = useCallback(() => setIsOpen(s => !s), [setIsOpen]);

  const openModal = useCallback((currentTitle, component) => {
    Component.current = component;
    setTitle(currentTitle);
    setIsOpen(true);
  }, []);

  return (
    <ModalContext.Provider value={{ toggleModal, closeModal, openModal }}>
      <>
        {children}
        <Modal
          isOpen={isOpen}
          toggle={toggleModal}
          style={{ minWidth: '360px', maxWidth: '500px', maxHeight: '97%', overflow: 'auto' }}
        >
          <ModalHeader tag="h3">{title || 'Confirm'}</ModalHeader>
          <ModalBody>{Component.current}</ModalBody>
        </Modal>
      </>
    </ModalContext.Provider>
  );
};
