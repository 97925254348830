import { orders } from './orders';
import products from './products';
import entities from './entities';
import assurance from './assurance';
import projects from './projects';
import asn from './asn';
import replenishment from './replenishment';
import ticketSearch from './ticket-search';
import common from './_common';
import uom from './uom';
import assignments from './assignments';
import ticketDetails from './ticket-details';
import sidenav from './sidenav';
import features from './feature-list';

// eslint-disable-next-line import/prefer-default-export
export const messages = {
  common,
  orders,
  products,
  entities,
  assurance,
  projects,
  asn,
  replenishment,
  sidenav,
  ticketSearch,
  ticketDetails,
  uom,
  assignments,
  features,
};
