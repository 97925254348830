let hasFailed = false;

let callback = (err = {}) => {
  alert(`FATAL: Could not load resource. ${err.name} ${err.message}`);
};

const handleFailure = e => {
  if (!hasFailed) {
    hasFailed = true;
    callback(e);
  }
};

export const onChunkFailure = fn => {
  callback = fn;
};

export const safeImport = promise =>
  promise.catch(e => {
    handleFailure(e);
  });
