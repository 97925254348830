import API from '../api';
import { Auth } from 'aws-amplify';
import _ from 'lodash';

const changePassword = async (userId, body) => {
  return API.post(`/v2/users/${userId}/password/reset`, body);
};

const login = async (username, password, source = 'connex-app') => {
  username = _.trim(username);
  password = _.trim(password);

  return API.post('/v2/users/login', {
    username,
    password,
    source,
  });
};

const logout = async () => {
  return API.post('/v2/users/logout', {}).then(() => {
    if (window?.FS && window.FS.anonymize) {
      window.FS.anonymize();
    }
  });
};

const signIn = async (username, password) => {
  username = _.trim(username);
  password = _.trim(password);

  return Auth.signIn(username, password);
};

const getUser = async (entityRef, userRef) => {
  return API.get(`/v2/${entityRef}/users/${userRef}`);
};

const getUserProfile = async userRef => {
  return API.get(`/v2/users/${userRef}/profile`, false);
};

const listUsers = async () => {
  return API.get(`/v2/users`);
};

const updateEmail = async (prevEmail, email) => {
  return API.patch(`/v2/users/update-email`, { prevEmail, email });
};

const updateUser = async (userRef, body) => {
  return API.put(`/v2/users/${userRef}`, body);
};

const reportAbuseEmail = async hash => {
  return API.patch(`/v2/users/abuse/${hash}`);
};

const requestAccess = async body => {
  return API.post('/v2/users/request-access', body);
};

const requestPasswordReset = async email => {
  return API.patch(`/v2/password`, { email });
};

const verifyChangeEmail = async hash => {
  return API.patch(`/v2/users/verify/${hash}`);
};

const verifyImport = async (entityRef, hash) => {
  return API.post(`/v2/admin/entities/${entityRef}/users/import/${hash}`);
};

const ssoLogin = async (entityRef, credentials) => {
  return API.post(`/v2/sso-auth/${entityRef}/users`, credentials);
};

const verifyPasswordReset = async hash => {
  return API.patch(`/v2/password/verify/${hash}`, {}, false);
};

const tokenRefresh = async body => {
  return API.post('/v2/users/tokenRefresh', body);
};

const submitNewEmail = async (hash, userId, email) => {
  return API.patch(`/v2/email/${hash}/${userId}`, { email });
};

const submitNewPassword = async (hash, userId, password) => {
  return API.patch(`/v2/password/${hash}/${userId}`, { password });
};

const adminResetUserPassword = async body => {
  return API.post('/v2/users/password/reset', body);
};

const userIsTrackingDevice = user => {
  const { entities = [] } = user;

  const trackers = entities
    .filter(e => e.assignedRoles && e.assignedRoles['tracking-device'] === true)
    .map(e => {
      return {
        ...e,
        vehicles: [{ crn: user.uid }],
      };
    });

  return trackers;
};

export default {
  signIn,
  adminResetUserPassword,
  login,
  logout,
  changePassword,
  getUser,
  getUserProfile,
  listUsers,
  updateEmail,
  updateUser,
  reportAbuseEmail,
  requestAccess,
  requestPasswordReset,
  verifyChangeEmail,
  verifyImport,
  verifyPasswordReset,
  submitNewPassword,
  submitNewEmail,
  tokenRefresh,
  userIsTrackingDevice,
  ssoLogin,
};
