import AppData from './app-data';
import { getEnvironment } from './env';
import CryptoJS from 'crypto-js';
import { Settings } from './globals';
import { decode, encode } from './security';
import { cacheItem, clearCachedItem, getCachedItem } from './cache';

const extract = (key, e) => {
  const bytes = CryptoJS.AES.decrypt(e, `${key}:${key.substr(9, 4)}`);

  return bytes.toString(CryptoJS.enc.Utf8);
};

const setEntityId = entity => {
  cacheItem('_entityId', entity);
};

const getEntityId = () => {
  return getCachedItem('_entityId');
};

const setUser = user => {
  cacheItem('user', user);
};

const getUser = () => {
  const user = getCachedItem('user', null);
  const currentEntity = getCurrentEntity();

  if (user?.s?.g && user?.s?.e) {
    user.groups = JSON.parse(extract(user.uid, user.s.g));
    user.entities = JSON.parse(extract(user.uid, user.s.e));
  }

  if (window?.FS) {
    if (user) {
      const displayName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || undefined;
      window.FS.identify(user.uid, {
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: currentEntity?.name,
        entityRef: currentEntity?.crn,
        displayName,
      });
    } else {
      window.FS.anonymize();
    }
  }

  return user;
};

const setUserInfo = () => {
  const user = getUser();
  const currentEntity = getCurrentEntity();

  if (window?.FS) {
    if (user && currentEntity) {
      const displayName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || undefined;
      window.FS.setUserVars({
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: currentEntity?.name,
        entityRef: currentEntity?.crn,
        displayName,
      });
    }
  }
};

const getUserSetting = (name, defaultValue = null) => {
  const user = getUser();
  const settings = user?.settings;

  return (settings && settings[name]) || defaultValue;
};

const locationServicesEnabled = () => {
  return getUserSetting('allowLocationServices', true);
};

const bypassMobileBackgroundLocationServices = () => {
  return getUserSetting('bypassMobileBackgroundLocationServices', false);
};

const getUserEntities = () => {
  const user = getUser();

  return (user && user.entities) || [];
};

const isMasterEntity = entityRef => {
  return entityRef === 'command-alkon-inc';
};

const isUserAdmin = () => {
  const user = getUser();

  return user !== null && user.userRef === user.uid;
};

const isUserPlatformAdmin = () => {
  return isUserAdmin();
};

const isUserPlatformSupport = () => {
  const user = getUser();

  return user !== null && user.groups && user.groups.findIndex(group => group.crn === 'support') >= 0;
};

const isUserPlatformEnablement = () => {
  const user = getUser();

  return user !== null && user.groups && user.groups.findIndex(group => group.crn === 'enablement') >= 0;
};

const isUserPlatformIngress = () => {
  const user = getUser();

  return user !== null && user.groups && user.groups.findIndex(group => group.crn === 'ingress') >= 0;
};

const isExperimentalUser = () => {
  const user = getUser();

  return isUserAdmin() || user?.groups?.findIndex(group => group.crn === 'experimental') >= 0;
};

const isUserInsightsAdmin = () => {
  const user = getUser();

  return user !== null && user.groups && user.groups.findIndex(group => group.crn === 'insights-admin') >= 0;
};

const isUserCompanyAdmin = entityRef => {
  if (isUserAdmin()) {
    return true;
  }

  const user = getUser();

  if (!user) {
    return false;
  }

  let isAdmin = false;

  user.entities
    .filter(e => e.crn === entityRef)
    .forEach(e => {
      if (e.authorities && e.authorities['company-admin'] === true) {
        isAdmin = true;
      }
    });

  return isAdmin;
};

const isUserInCompanyRole = (roleRef, entityRef) => {
  const user = getUser();

  if (!user) {
    return false;
  }

  let isInRole = false;

  user.entities &&
    user.entities
      .filter(e => !entityRef || e.crn === entityRef)
      .forEach(e => {
        if (e.authorities && e.authorities[roleRef] === true) {
          isInRole = true;
        }
      });

  return isInRole;
};

const useDriverExperience = () => {
  const entity = getEntity();
  const entityRef = entity && entity.crn;

  return isUserInCompanyRole('company-driver', entityRef) && !isUserInCompanyRole('company-admin', entityRef);
};

const getUserRoles = entityRef => {
  const roles = [],
    user = getUser();

  if (user) {
    user.entities
      .filter(e => e.crn === entityRef)
      .forEach(entity => {
        Object.keys(entity.authorities).forEach(a => roles.push(a));
      });
  }

  return roles;
};

const userHasAssignedRole = (entityRef, role) => {
  const { entities = [] } = getUser();

  return entities.filter(e => e.assignedRoles && e.assignedRoles[role] === true).length > 0;
};

const decodeEntity = entity => {
  if (entity?.s?.f && entity?.s?.r) {
    entity.licensedFeatures = JSON.parse(extract(entity.crn, entity.s.f));
    entity.roles = JSON.parse(extract(entity.crn, entity.s.r));
  }
};

const getEntity = () => {
  const e = getCachedItem('entity', null);

  if (e?.s?.a) {
    e.authorities = JSON.parse(decode('CONNEX', e.s.a));
  }

  return e;
};

const setEntity = e => {
  if (e?.authorities) {
    e.s = {
      a: encode('CONNEX', JSON.stringify(e.authorities)),
    };

    delete e.authorities;
  }

  return cacheItem('entity', e);
};

const getCurrentEntity = () => {
  const e = getCachedItem('current-entity', null);

  if (e?.v?.f) {
    e.licensedFeatures = JSON.parse(decode('CONNEX', e.v.f));
  }

  return e;
};

const setCurrentEntity = e => {
  if (e?.licensedFeatures) {
    e.v = {
      f: encode('CONNEX', JSON.stringify(e.licensed)),
    };

    delete e.licensedFeatures;
  }

  return cacheItem('current-entity', e);
};

const getCurrentCompany = () => {
  const e = getExperience();

  e?.company && decodeEntity(e.company);

  return (e && e.company) || {};
};

const getFeatures = () => {
  const e = getExperience();

  return (e && e.navigation && e.navigation.features) || {};
};

const getNavigation = () => {
  const e = getExperience();

  return (e && e.navigation) || { items: [] };
};

const getConfigModel = configId => {
  const nav = getNavigation() || { items: [] };
  const { items } = nav;

  for (let i = 0; i < items.length; i++) {
    if (items[i].config && items[i].config.crn === configId) {
      return items[i].config;
    }

    const children = items[i].children || [];

    for (let j = 0; j < children.length; j++) {
      const config = children[j].config || {};

      if (config.crn === configId) {
        return config;
      }
    }
  }

  return null;
};

const getNavigationFeatures = () => {
  const e = getExperience();

  return (e && e.navigation && e.navigation.features) || {};
};

const isLicensed = licenseId => {
  const features = getNavigationFeatures();
  return !licenseId || features[`${licenseId}License`] === true;
};

const getLicensedFeatures = () => {
  const e = getExperience();

  return e?.licensedFeatures ?? [];
};

const setExperience = e => {
  Settings.experience = e;
};

const getExperience = () => {
  return Settings.experience;
};

const getWorkspaces = async entityRef => {
  return await AppData.listItems('connex', entityRef, 'workspace');
};

const getModel = () => {
  return getCachedItem('__model', null);
};

const setModel = e => {
  return cacheItem('__model', e);
};

const getUserApps = u => {
  const user = u || getUser();

  if (user === null) return [];

  return user.apps || [];
};

const userHasAccess = appId => {
  const user = getUser();

  if (user !== null) {
    return user.apps && user.apps.filter(a => a.crn === appId).length > 0;
  }

  return false;
};

const getUserEntitiesForApp = appId => {
  const user = getUser();

  if (user === null) return [];

  const companies = [];

  user.entities.forEach(entity => {
    entity.roles.forEach(role => {
      if (role === appId) {
        companies.push(entity);
      }
    });
  });

  return companies.sort((a, b) => (a.name < b.name && -1) || 1);
};

const pinLocation = () => {
  const path = window.location.hash.substr(1);

  cacheItem('pinned-location', path);
};

const getPinnedLocation = (defaultLocation = '/') => {
  return getCachedItem('pinned-location', defaultLocation);
};

const findAuthorities = entityRef => {
  const user = getUser();

  if (user) {
    if (isUserAdmin()) {
      return {
        'company-admin': true,
      };
    }

    const connexions = user.entities.filter(c => c.crn === entityRef);
    const connexion = connexions.length > 0 ? connexions[0] : null;

    if (connexion !== null) {
      return connexion.authorities || {};
    }
  }

  return {};
};

const getCurrentAuthorities = () => {
  const entity = getEntity();

  return (entity && findAuthorities(entity.crn)) || {};
};

const userHasPermission = (pids, companyAdminAllowed = true) => {
  if (pids && pids.includes('insights-admin') && isUserInsightsAdmin()) {
    return true;
  }

  if (pids && pids.includes('platform-support') && isUserPlatformSupport()) {
    return true;
  }

  if (pids?.includes('platform-enablement') && isUserPlatformEnablement()) {
    return true;
  }

  if (pids?.includes('platform-ingress') && isUserPlatformIngress()) {
    return true;
  }

  if (pids && pids.includes('platform-admin') && !isUserAdmin()) {
    return false;
  }

  if (pids && pids.includes('company-admin') && isUserAdmin()) {
    return true;
  }

  if (!pids || isUserAdmin()) {
    return true;
  }

  const entity = getEntity();

  companyAdminAllowed = companyAdminAllowed && pids.indexOf('!company-admin') === -1;

  if (entity && companyAdminAllowed && (isUserCompanyAdmin(entity.crn) || isUserPlatformSupport())) {
    return true;
  }

  const auth = getCurrentAuthorities();

  let okay = false;

  if (typeof pids === 'string') {
    pids.split(',').forEach(id => {
      if (auth[id] === true) {
        okay = true;
      }
    });
  }

  return okay;
};

const setCurrentTheme = theme => {
  cacheItem('__theme__', theme);
};

const getCurrentTheme = (defaultTheme = 'dark') => {
  return getCachedItem('__theme__', defaultTheme);
};

const getCachedTab = (entityRef, ticket) => {
  const selectedIndexCacheKey = '#ticket-editor_selected-index';

  let cachedIndex = getCachedItem(selectedIndexCacheKey, {});

  if (cachedIndex.entityRef !== entityRef || cachedIndex.crn !== ticket.crn) {
    cachedIndex = null;
    clearCachedItem(selectedIndexCacheKey);
  }

  return {
    selectedIndexCacheKey,
    selectedIndex: (cachedIndex && cachedIndex.selectedIndex) || 0,
  };
};

const setCachedTab = (entityRef, crn, selectedIndex) => {
  cacheItem('#ticket-editor_selected-index', { entityRef, crn, selectedIndex });
};

const getCompanyLicenses = licenseIds => {
  const { licensedFeatures = [] } = getCurrentCompany();

  return licensedFeatures.filter(licence => {
    if (licenseIds.includes(licence.id)) return licence;
  });
};

export default {
  setCachedTab,
  getCachedTab,
  setCurrentTheme,
  getCurrentTheme,
  getConfigModel,
  getModel,
  setModel,
  getEnvironment,
  getEntity,
  setEntity,
  getCurrentEntity,
  getCurrentCompany,
  setCurrentEntity,
  getCurrentAuthorities,
  getExperience,
  setExperience,
  getLicensedFeatures,
  getNavigationFeatures,
  getFeatures,
  getNavigation,
  findAuthorities,
  pinLocation,
  getPinnedLocation,
  getUser,
  getUserSetting,
  getUserRoles,
  userHasAssignedRole,
  setUser,
  getUserEntities,
  getWorkspaces,
  isUserAdmin,
  isUserPlatformAdmin,
  isUserPlatformSupport,
  isUserPlatformEnablement,
  isUserPlatformIngress,
  isUserInsightsAdmin,
  isUserCompanyAdmin,
  isUserInCompanyRole,
  isLicensed,
  useDriverExperience,
  getUserApps,
  userHasAccess,
  getUserEntitiesForApp,
  setEntityId,
  getEntityId,
  cacheItem,
  getCachedItem,
  clearCachedItem,
  userHasPermission,
  isMasterEntity,
  getCompanyLicenses,
  isExperimentalUser,
  locationServicesEnabled,
  bypassMobileBackgroundLocationServices,
  setUserInfo,
};
