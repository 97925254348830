export default {
  'View Shipments': 'Ver Envíos',
  actions: 'Acciones',
  ticket_add_action: 'Agregar Ticket',
  tickets_export_action: 'Exportación Masiva',
  tickets_export_pdf_action: 'Exportación Masiva PDF',

  searchCriteria: 'Criterios de Búsqueda',
  startDate: 'Desde',
  endDate: 'Hasta',
  ticketNumber: 'Número de Ticket',
  search: 'Buscar',
  filters: 'Filtros',

  numberOfTicketsFounds: `({searchCount, plural,
    =0 {0 Tickets Encontrados}
    one {1 Ticket Encontrado}
    other {# Tickets Encontrados}
  })`,

  'lookupKeyLabel_Ticket Number': 'Número de Ticket',

  created: 'Fecha de Creación',
  id: 'Ticket',
  customerstatus: 'Estado del Cliente',
  vehicle: 'Vehículo',
  'project,projectname': 'Proyecto',
  'customer,customername': 'Cliente',
  'carrier,carriername': 'Transportadora',
  dispatchorder: 'Órden de Despacho',

  discipline: 'Disciplina',
  'product,productdescription': 'Producto',
  'quantity,uom': 'Cantidad',
  invoice: 'Número de Factura',
  'origin,origindescription': 'Origen',
  latestEventStatus: 'Último Estado',
  'dispatchOrder.totalTicketed.loadNumber': 'Carga No.',
  'dispatchOrder.totalTicketed.value,uom': 'Ticket Creado',
  'dispatchOrder.totalOrdered.value,uom': 'Pedido',
  attachmentcount: 'Archivos Adjuntos',
  syncStatus: 'Estado de Sincronización',

  filterColumn_customerstatus: 'Código de Estado',
  filterColumn_customer: 'Cliente',
  filterColumn_project: 'Proyecto',
  filterColumn_vehicle: 'Vehículo',
  filterColumn_product: 'Producto Primario',
  filterColumn_dispatchorder: 'Órden de Despacho',
  filterColumn_carrier: 'Transportadora',
  filterColumn_invoice: 'Factura',
  filterColumn_primaryproducttype: 'Tipo de Producto Primario',
  filterColumn_voided: 'Mostrar Tickets Anulados',
  filterColumn_purchaseorder: 'Órden de Compra',
  filterColumn_origin: 'Planta de Origen',
  filterColumn_address: 'Dirección',
  filterColumn_customerjob: 'Obra del Cliente',
  filterColumn_latestEventStatus: 'Último Estado',
  filterColumn_destinationInstructions: 'Instructiones para el Destino',
  filterColumn_attachmentcount: 'Tiene Archivos Adjuntos',

  filterColumn_supplier: 'Proveedor',
  filterColumn_jobarea: 'Área de Obra',

  clearFilters: 'Limpiar Filtros',
  saveAsDefault: 'Guardar como Predeterminado',

  'Accept Materials': 'Aceptar Materiales',
  tickets_import_action: 'Importación Masiva',
  tickets_nav_action: 'Edición Masiva',
  jobareadescription: 'Área de Obra',
  'supplier,suppliername': 'Proveedor',
};
