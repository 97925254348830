export default {
  table: {
    name: 'Nombre',
    description: 'Descripción',
    userName: 'Nombre de Usuario',
    state: 'Estado',
    identifier: 'Identificador',
    devices: 'Dispositivos',
    drivers: 'Conductores',
  },
};
