export default {
  button: {
    create: 'Create Order',
  },
  grid: {
    deliveryWindow: 'Delivery Window',
    deliveryLocation: 'Delivery Location',
    customer: 'Customer',
    purchaseOrder: 'Purchase Order',
    products: 'Products',
    deliveries: 'Deliveries',
    qty: 'Qty Delivered',
    customerStatus: 'Customer Status',
    supplierStatus: 'Supplier Status',
    orderReference: 'Reference',
  },
  products: {
    productsServices: 'Products & Services',
    closestProducts: 'Display the closest products to my delivery location',
  },
};
