import React, { useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { consolidateMessages } from './util/consolidateMessages';
import { messages as defaultGlobalMessages } from './messages-global';
import { messages as defaultFeatureMessages } from './messages-features';
import { fetchPreferredLocale, getBrowserLocale } from './util/locale';
import useApiCall from '../@cai-connex/react/hooks/useApiCall';

export const LOCALES = [
  { id: 'en-US', description: 'English (US)' },
  { id: 'es', description: 'Español' },
  { id: 'en-AU', description: 'English (AU)' },
];

export const IntlContext = React.createContext();

export const useIntlContext = () => {
  const context = useContext(IntlContext);
  if (!context) {
    throw new Error('useIntlContext cannot be called outside of the IntlContext context provider');
  }
  return context;
};

export const Internationalization = ({
  children,
  globalMessages = defaultGlobalMessages,
  moduleMessages = defaultFeatureMessages,
}) => {
  const [userLocale, setUserLocale] = useState(null);
  const [messages, setMessages] = useState(null);

  const getPreferredLocale = useApiCall(fetchPreferredLocale);

  useEffect(() => {
    const getLocale = async () => {
      const preferredLocale = await getPreferredLocale();
      setUserLocale(preferredLocale || getBrowserLocale());
    };

    getLocale();
  }, []);

  useEffect(() => {
    if (userLocale) {
      setMessages(consolidateMessages({ globalMessages, moduleMessages })[userLocale]);
    }
  }, [userLocale]);

  const handleIntlError = React.useCallback(
    error => {
      if (error?.code === 'MISSING_TRANSLATION') {
        if (!error?.descriptor?.defaultMessage || userLocale !== 'en-US') {
          console.warn(`Missing message: "${error?.descriptor?.id}"`);
        }
        return;
      }
      console.error(error.message);
    },
    [userLocale]
  );

  return !userLocale || !messages ? null : (
    <IntlContext.Provider value={{ userLocale, setUserLocale, messages }}>
      <IntlProvider locale={userLocale} messages={messages} onError={handleIntlError}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
