/* eslint-disable */
import API from '../api';

const updateUserProfile = async profile => {
  return API.patch(`/v2/users/${profile.crn}`, profile);
};

const getUserProfile = async userRef => {
  return API.get(`/v2/users/${userRef}`);
};

export default {
  getUserProfile,
  updateUserProfile,
};
